import React from 'react'

// App components
import CustomLink from '../../components/CustomLink'
import InfusionsoftForm from '../../components/InfusionsoftForm'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'
import { styles as buttonStyles } from '../../components/Button'

// App utils
import { rhythm } from '../../utils/typography'
import { themeStyles, colors, metrics } from '../../utils/theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    marginTop: metrics.defaultMargin,
  },
  topDiv: {
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  fillOutFormDiv: {
    backgroundColor: '#f2f6f6',
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '30px',
    padding: '40px',
  },
  fillOutFormText: {
    fontWeight: '400',
    fontSize: '18px',
    letterSpacing: '.5px',
  },
  onlineText: {
    color: colors.brightBlue,
    fontSize: '18px',
    letterSpacing: '.5px',
  },
}

/**
 *
 */

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={{ ...themeStyles.contentWidth }}>
          <PageTitle title="Orthodontic Referral" />
          <div css={styles.container}>
            <div id="ortho-complete-form-top-div" css={styles.topDiv}>
              <p css={styles.onlineText}>
                Please complete <b>the orthodontic referral form.</b>
              </p>
            </div>
            <div id="orth-fill-out-form-div" css={styles.fillOutFormDiv}>
              <p id="ortho-fill-out-form-text" css={styles.fillOutFormText}>
                Fill out the form <span css={styles.onlineText}>online</span>
              </p>
              <a
                href="https://intakeq.com/new/vyiugq"
                target="_blank"
                css={buttonStyles.button}
                id="ortho-fill-out-form-button"
              >
                Online Form
              </a>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
